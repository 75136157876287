@import 'styles/index.scss';

.form-button {
    width: 100%;
    max-width: 374px;
    border: none;
    height: 44px;
    color: #000000;
    background: linear-gradient(121.23deg, #FBDA34 14.86%, #F1A914 100%);
    margin: 37px auto 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    border-radius: $radius;

    @media screen and (min-width: 768px) {
        height: 44px;
    }
}

.form-button:disabled {
    opacity: 0.2;
}

.ghost {
    color: rgba(255, 255, 255, 0.92);
    background: rgba(255, 255, 255, 0.05);
    border: rgba(255, 255, 255, 0.2) 1px solid;

    &:hover {
        color: #FFFFFF;
        background: rgba(255, 255, 255, 0.12);
        border: 1px solid rgba(255, 255, 255, 0.7);
    }

    &:active {
        color: $color-primary-brand;
        background: $color-background-loading;
        border: 1px solid $color-primary-brand;
    }
}
