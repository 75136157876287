.root {
    text-align: center;
    flex-direction: column;
}

.version {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.7);
}