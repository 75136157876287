@import './colors.scss';

$radius: 4px;

body {
    margin: 0;
    font-family: 'CH5Medium', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'CH5Regular';
    font-display: swap;
    src: local('CH5Regular'), url('./fonts/CH5-Regular.woff2') format('woff2'),
        url('./fonts/CH5-Regular.woff') format('woff');
}

@font-face {
    font-family: 'CH5Bold';
    font-display: swap;
    src: local('CH5Bold'), url('./fonts/CH5-Bold.woff2') format('woff2'),
    url('./fonts/CH5-Bold.woff') format('woff');
}

@font-face {
    font-family: 'CH5Medium';
    font-display: swap;
    src: local('CH5Medium'), url('./fonts/CH5-Medium.woff2') format('woff2'),
    url('./fonts/CH5-Medium.woff') format('woff');
}

.app {
    * {
        font-family: 'CH5Regular';
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;

        @media screen and (max-width: 499px) {
            font-size: 16px;
        }
    }
}

a {
    font-family: 'CH5Regular';
    line-height: 1.22;
    letter-spacing: 0.35px;
}

.error {
    display: block;
    border: none;
    width: 100%;
    padding: 0;
    margin: 20px 0 0;
    text-align: center;
    font-size: 16px;
    height: 16px;
    font-family: 'CH5Regular';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.16px;
    color: #ff4040;
}