@import '../../styles/colors.scss';

.register {
    width: 100%;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .submit-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 0 1rem;
    }

    .register-fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        input {
            max-width: 438px;
        }

        @media screen and (max-width: 499px) {
            flex-direction: column;
        }

        .column-2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .form-input-group {
                max-width: 195px;

                @media screen and (max-width: 500px) {
                    max-width: 438px;
                }
            }
            @media screen and (max-width: 500px) {
                flex-direction: column;
            }
        }

        .form-input-group {
            max-width: 694px;
            display: flex;
            align-items: center;
            flex-direction: column;

            @media screen and (min-width: 500px) {
                margin: 0 2%;
                width: 420px;
                align-items: flex-start;
            }

            @media screen and (max-width: 499px) {
                display: inline;
            }

            .double-label {
                padding-bottom: 6px;
               
                @media screen and (max-width: 500px) {
                    margin-right: -0.25em;
                }

                .tooltip {
                    @media screen and (min-width: 500px) {
                        margin-right: 16px;
                    }
                }
            }

            .no-padding {
                padding: 0;
            }
        }
    }

    .date {
        label {
            opacity: 1;
        }
    }

    .terms {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 0;

        & > * {
            white-space: nowrap;
            margin-bottom: 8px;
        }

        @media screen and (max-width: 499px) {
            margin: 1rem auto;
        }

        @media screen and (max-width: 380px) {
            justify-content: center;
        }

        .terms-check {
            border: 1px solid #ebebeb;
            background-color: transparent;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            cursor: pointer;
            outline: none;

            @media screen and (max-width: 499px) {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }

            &.accepted {
                background-image: url('../../styles/assets/tick-box.svg');
                background-repeat: no-repeat;
                background-size: 26px 26px;
                background-position: center;
            }
        }
    }

    .req-acc {
        
         @media screen and (max-width: 499px) {
            justify-content: flex-start;
        }
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $white;
    }

    select::-ms-expand {
        display: none;
    }
}

.subtitle1 {
    font-family: 'CH5Medium';
    font-size: 1rem;
}

.subtitle2 {
    font-family: 'CH5Medium';
    font-size: 0.9rem;
}

.headline5 {
    font-family: 'CH5Medium';
    font-size: 1.25rem;
}

.react-date-picker__calendar {
    top: -260px !important; // Yes I know, but I could not find a better way with this calendar.

    @media screen and (max-width: 500px) {
        max-width: 86vw;
    }
}

.form-calendar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
}

.react-date-picker__wrapper {
    border: none;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    border: 1px solid #a0a096;
    @extend .subtitle1;
    line-height: 21px;
    letter-spacing: -0.31px;

    outline: $color-opacity-e solid 1px;
    border-radius: 4px;
}

.react-date-picker__calendar .react-calendar {
    border-width: 0;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    background-color: $color-secondary-dark-grey;
    padding: -40px;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:hover,
.react-calendar button:hover abbr {
    background-color: $color-primary-brand;
    color: #000;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
    background-color: $color-primary-brand;
    color: #000;
}

.react-calendar button:hover span {
    background-color: inherit;
    color: #000;
}

.react-calendar__navigation {
    background-color: $color-primary-surface;
    display: flex;
    height: 44px;
    margin-bottom: 0;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: $color-opacity-a;
    @extend .subtitle1;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: $color-primary-brand;
    color: #000;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px !important;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday abbr {
    color: $color-opacity-b;
    font-size: 14px !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
    font-weight: bold;
}

.react-calendar__month-view__days__day abbr {
    font-size: 16px;
    color: white;
}

.react-calendar__tile--active {
    background: $color-primary-brand;
    color: white;
}

.react-calendar__tile--active abbr {
    background-color: $color-primary-brand;
    color: #000;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: $color-primary-brand;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__navigation .react-calendar__navigation__label__labelText {
    background-color: inherit;
    font-size: 14px;
}

.react-calendar__navigation .react-calendar__navigation__label__labelText:hover {
    background-color: inherit;
    color: #000;
}

.react-calendar__navigation__label__labelText {
    @extend .subtitle2;
    background-color: $color-primary-brand;
    color: $color-opacity-a;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__year-view__months__month,
.react-calendar__year-view__months__month abbr,
.react-calendar__decade-view__years__year {
    font-family: 'CH5Medium';
    color: white;
    font-size: 16px;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    text-align: center;
    @extend .subtitle1;
    letter-spacing: -0.31px;
    line-height: 21px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}

.react-calendar__tile--hasActive {
    background: $color-primary-brand;
}

.react-calendar__tile--hasActive abbr {
    background: $color-primary-brand;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: $color-opacity-a;
}

.react-date-picker__button .react-date-picker__button__icon,
.react-date-picker__button .react-date-picker__button__icon {
    stroke: $color-opacity-b;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: $color-primary-brand;
}

.react-date-picker__inputGroup {
    @extend .headline5;
}

.react-date-picker__inputGroup__input {
    text-align: center;
    color: #fff;
}

.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month,
.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day {
    min-width: 25px;
}

.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
    min-width: 48px;
}

.react-date-picker__inputGroup {
    flex-grow: 0;
}
