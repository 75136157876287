@import '../../styles/colors.scss';

.password-container {
    position: relative;

    .forgotten-password {
        position: absolute;
        white-space: nowrap;
        right: 0;
        bottom: 6px;
        font-family: 'CH5Regular';
        text-decoration: none;
        line-height: 1rem;
        text-align: right;
        cursor: pointer;
        font-size: 14px;
        color: $color-primary-brand;
        letter-spacing: 0.25px;

        &:hover {
            color: $white;
        }
    }
}

.submit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 2rem;
}

.terms {
    padding-bottom: 40px;
}