@import './styles/colors.scss';

body,
html,
div,
span,
p {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background: linear-gradient(156.3deg, #50626D 0%, #141B21 84.75%);
    font-family: 'CH5Regular', sans-serif;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    border: none;
    color: white;
    float: none;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 15px;
    text-align: left;
}

.link,
.link:visited,
.link:focus {
    font-family: 'CH5Regular';
    line-height: 1rem;
    letter-spacing: 0.25px;
    color: $white;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;

    @media screen and (max-width: 499px) {
        font-size: 14px;
    }
}

.app {
    width: 502px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 48px;
    max-width: 100%;
    background: transparent;

    .change-password-screen,
    .forgot-password-screen,
    .signin-screen {
        width: 462px;
        margin: auto;
        background: $color-primary-surface;
        padding: 2rem 31px;
        margin-bottom: 4rem;
        border: 1px solid rgba(255, 255, 255, 0.12);
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: none;



        @media screen and (max-width: 543px) {
            width: auto;
        }
    }
}

.app.register {
    padding-top: 0;
}

.headers {
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 502px;
    text-align: center;
    background: $color-primary-surface;
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 2rem 31px;
    border-bottom: none;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    &.register {
        @media screen and (max-width: 798px) {
            width: auto;
        }

        .link.header {
            max-width: 316px;
        }
    }

    .link.header {
        opacity: 0.7;
        padding-bottom: 17px;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.18px;
        text-decoration: none;
        text-align: center;
        display: block;
        flex: 50% 0 0;
        border-bottom: 1px solid transparent;
        color: $color-primary-white;
        max-width: 199px;

        &.link.active,
        &.link.active:visited {
            border-bottom: 1px solid $color-primary-brand;
            opacity: 0.92;
        }

        @media screen and (max-width: 400px) {
            font-size: 1rem;
        }

        @media screen and (min-width: 499px) and (max-width: 499px) {
            font-size: 20px;
        }
    }

    .link.header:nth-child(2) {
        @media screen and (max-width: 1024px) {
            margin-left: 28px;
        }

        margin-left: 54px;
    }


}

.my5_logo {
    position: relative;
    right: 0;
    width: 90%;
    height: 100px;
    top: 24px;
    left: 30px;
    background: transparent;
    border: none;
    background-image: url('./styles/assets/5.svg');
    background-repeat: no-repeat;
    background-size: 113px 67px;
    background-position-x: left;
    outline: none;
    cursor: default;
}

.extra-content {
    display: flex;
    font-size: 14px;
    font-family: 'CH5Regular';
    margin: 24px auto 0;
    text-align: center;
    line-height: 1.22;
    letter-spacing: 0.35px;
    font-weight: 400;
    width: 438px;
    align-items: center;
    justify-content: center;
    .create-account {
        margin-right: 8px;
        font-size: 14px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.7);
        margin-left: 4rem;

        @media screen and (max-width: 499px) {
            margin: 2px;
            text-align: center;
            font-size: 12px;
        }
    }

    .terms-confirm-text {
        margin-right: 8px;
        font-size: 14px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.7);
        font-family: 'CH5Regular';
        cursor: pointer;

        &.light {
            margin-right: 2px;;
            color: #FFFFFF;
        }
    }

    @media screen and (max-width: 499px) {
        width: 100%;
        text-align: left;
    }

    .link {
        font-family: 'CH5Regular';
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding-right: 2px;

        letter-spacing: 0.25px;
        cursor: pointer;
        color: $color-primary-brand;

        @media screen and (max-width: 499px) {
            padding-right: 1px;
            font-size: 14px;
            width: 105px;
        }

        &:hover {
            color: $white;
        }

        &.privacy {
            margin-top: 64px;

            @media screen and (max-width: 325px) {
                margin-top: 1rem;
            }
        }
    }

}

.spinner-container {
    position: absolute;
    align-self: center;
    margin: auto;
    bottom: -36px;
}

.spinner {
    border: 5px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    border-top: 5px solid $color-primary-brand;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spinner 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.register-screen {
    display: flex;
    max-width: 758px;
    justify-content: center;
    margin: auto;
    background: $color-primary-surface;
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 2rem 20px;
    border-top: none;
}
