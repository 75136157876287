$black0: rgba(0, 0, 0, 0);
$black83: rgba(0, 0, 0, 0.83);
$black9: rgba(0, 0, 0, 0.9);
$black7: rgba(0, 0, 0, 0.7);
$black-disabled: rgba(3, 8, 44, 0.7);
$midnight: #010b1e;
$blue: #36467f;
$bright-cyan: #47c8ff;
$bright-cyan2: rgba(71, 200, 255, 0.2);
$navy: #001236;
$white: #ebebeb;
$black: #000000;
$link: #3ec7ff;
$white: rgba(255, 255, 255, 1);
$white-tr: rgba(235, 235, 235, 0.5);
$white3: rgba(235, 235, 235, 0.3);
$white8: rgba(235, 235, 235, 0.8);
$white13: rgba(255, 255, 255, 0.13);

$color-red: #d91f00;
$color-secondary-dark-6: #141b21;
$color-secondary-white: #ffffff;
$color-primary-brand: #fed141;
$color-primary-surface: #1b2329;
$color-primary-white: #ffffff;

$color-opacity-a: rgba(255, 255, 255, 0.92); // opacity-a
$color-opacity-b: rgba(255, 255, 255, 0.7); // opacity-b
$color-opacity-c: rgba(255, 255, 255, 0.3); // opacity-c
$color-opacity-e: rgba(255, 255, 255, 0.12); // opacity-e
$color-background-loading: rgba(255, 255, 255, 0.1);

$color-secondary-dark-grey: #141b21;
