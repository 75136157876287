@import '../../styles/colors.scss';

.form-input-group {
    position: relative;
    padding-bottom: 30px;
    &:focus-within {
        .input-label {
            opacity: 1;
        }
    }

    .input-label {
        font-family: 'CH5Regular';
        opacity: 0.7;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $color-primary-white;
        width: 100%;
        text-align: left;
        float: left;

        @media screen and (max-width: 499px) {
            font-size: 16px;
        }
    }

    .input-field {
        font-family: 'CH5Regular';
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        padding: 5px 0;
        text-indent: 0;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0.6px;
        color: $color-primary-white;
        opacity: 0.92;
        background-color: transparent;
        width: 100%;
        letter-spacing: -0.45px;

        &:focus {
            border-bottom: 1px solid #ebebeb;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 99999s ease-in-out 0s;
            -webkit-text-fill-color: #ebebeb !important;
        }

        @media screen and (max-width: 499px) {
            font-size: 17px;
        }
    }

    .input-error {
        font-family: 'CH5Regular';
        font-size: 14px;
        border: none;
        position: absolute;
        bottom: 0;
        font-size: 14px;
        margin: 0 20px 10px 0;
        color: #fc361d;
        left: 0;
    }

    *:focus {
        border: none;
        outline: none;
    }

    .show-hide-icon {
        position: absolute;
        right: 0;
        width: 36px;
        height: 22px;
        top: 22px;
        background: transparent;
        border: none;
        background-image: url('../../styles/assets/eye.png');
        background-repeat: no-repeat;
        background-size: 36px 22px;
        opacity: 0.2;
        font-size: 0;
        cursor: pointer;

        &.visible {
            opacity: 1;
        }
    }

    .password {
        padding-right: 44px;
    }
}
